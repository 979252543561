import { TopicsTotalType } from "./types"
import { FaqType } from "../../shared/types"

export const getTopicsTotal = (
  topicsTotal: TopicsTotalType,
  faqs: FaqType[]
): TopicsTotalType => {
  let newTopicsTotal = { ...topicsTotal }
  faqs.map(topic => {
    topic.node.data.categories.forEach(cat => {
      newTopicsTotal[cat.category] += 1
    })
  })

  return newTopicsTotal
}
