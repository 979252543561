import { FaqTopics } from "../../../../FaqPages/shared"
import { PopularTopic } from "../../../shared/types"
import { FaqType } from "components/FaqPages/shared/types"

interface ITherapistsPopularCitiesProps {
  topics: FaqType[] | PopularTopic[]
}

export const TherapistsPopularTopics = ({
  topics,
}: ITherapistsPopularCitiesProps) => {
  return (
    <FaqTopics
      modifiedHeader={true}
      title="Learn more about therapy"
      topics={topics}
    />
  )
}
