import { FaqCategoriesInfoType, FaqType } from "../types"
import { TopicsTotalType } from "./types"
import { getTopicsTotal } from "./utils"
import { convertToKebabCase } from "../../shared/utils"
import { GeneratedGatsbyImage } from "../../../utils/utils"

import {
  Container,
  Category,
  CategoryText,
  CategoryTitle,
  TopicsLink,
} from "./styled"

export const FaqCategoryLinks = ({
  categoriesInfo,
  faqs,
}: {
  categoriesInfo: FaqCategoriesInfoType
  faqs: FaqType[]
}) => {
  let topicsTotal: TopicsTotalType = categoriesInfo.category_info.reduce(
    (acc, cur) => ({ ...acc, [cur.title.text]: 0 }),
    {}
  )

  return (
    <Container>
      {categoriesInfo.category_info.map(category => (
        <Category key={category.title.text}>
          <CategoryText>
            <CategoryTitle>{category.title.text}</CategoryTitle>
            <TopicsLink
              to={`/faqs/${convertToKebabCase(category.title.text)}`}
            >{`${
              getTopicsTotal(topicsTotal, faqs)[category.title.text]
            } Topics`}</TopicsLink>
          </CategoryText>
          <GeneratedGatsbyImage
            imageData={category.landing_image}
            alt={category.title.text}
          />
        </Category>
      ))}
    </Container>
  )
}
